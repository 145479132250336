<template>
  <div class="shop-product-list-page">
    <!-- [筛选项] -->
    <kr-card :padding="[0, 0, 12, 0]" @rect:update="onTopCardRectUpdate">
      <a-form layout="inline" :form="queryParams">
        <div class="filter-condition-form">
          <a-form-item label="序号：">
            <a-input
              v-model.trim="queryParams.id"
              allowClear
              placeholder="请输入序号"
              style="width: 180px"
              @keypress.enter.native="reload"
              @change="queryParams.id = queryParams.id.replace(/^[0]+/, '')"
            />
          </a-form-item>
          <a-form-item label="名称：">
            <a-input
              v-model.trim="queryParams.item_name"
              allowClear
              placeholder="请输入商品名称"
              style="width: 180px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item label="商品ID：" class="shop-id-item">
            <a-input
              v-model.trim="queryParams.item_id"
              allowClear
              placeholder="请输入商品ID"
              style="width: 180px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item label="店铺：">
            <a-input
              v-model.trim="queryParams.shop_name"
              allowClear
              placeholder="请输入店铺名称"
              style="width: 180px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <!--  <a-form-item label="公司：">
              <a-input
                v-model.trim="queryParams.supplier_name"
                allowClear
                placeholder="请输入公司名称"
                style="width: 180px"
                @keypress.enter.native="reload"
              />
            </a-form-item>-->
          <a-form-item label="状态：">
            <a-select
              v-model="queryParams.check_status"
              placeholder="审核状态"
              allowClear
              style="width: 180px"
            >
              <a-select-option
                v-for="(item, key) in checkStatusAllOptions"
                :key="key"
                :value="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item>
            <div class="btn-lines">
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="showAllQuery">高级筛选</a-button>
              <a-button @click="reset">重置</a-button>
              <a-button :loading="exportLoading" @click="exportListClick">导出</a-button>
            </div>
          </a-form-item>
        </div>
      </a-form>
    </kr-card>
    <!-- [列表] -->
    <a-table
      class="tablecl"
      :class="list.length * 56 < tableScrollY ? 'less-table' : ''"
      :scroll="{ x: 1000, y: tableScrollY }"
      :rowKey="record => record.id"
      :columns="goodsColumns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="{ emptyText: '暂无商品' }"
      @change="paginationHandler"
    >
      <template slot="goods_img" slot-scope="record">
        <my-image
          :imgWidth="40"
          style="margin: 0px auto"
          v-if="record.item_id__pict_url || record.item_id__slide_images[0]"
          :imgUrl="record.item_id__pict_url || record.item_id__slide_images[0]"
        />
      </template>
      <template slot="item_id__commission_rate" slot-scope="record">
        {{
          record.item_id__commission_rate || record.item_id__commission_rate === 0
            ? record.item_id__commission_rate / 100 + '%'
            : '--'
        }}
      </template>
      <template slot="item_id__exclusive_commission_rate" slot-scope="record">
        {{
          record.item_id__exclusive_commission_rate ||
          record.item_id__exclusive_commission_rate === 0
            ? record.item_id__exclusive_commission_rate / 100 + '%'
            : '--'
        }}
      </template>
      <template slot="item_id__colonel_commission_rate" slot-scope="record">
        {{
          record.item_id__colonel_commission_rate || record.item_id__colonel_commission_rate === 0
            ? record.item_id__colonel_commission_rate / 100 + '%'
            : '--'
        }}
      </template>

      <template slot="is_recycle" slot-scope="record">
        <a-tag v-if="record.is_recycle === 0" color="green">否</a-tag>
        <a-tag v-if="record.is_recycle === 1" color="red">是</a-tag>
      </template>
      <template slot="check_status" slot-scope="record">
        <div
          :style="{
            color:
              record.check_status === 3 || record.check_status === 7
                ? 'red'
                : record.check_status === 5
                ? 'green'
                : '',
          }"
        >
          {{
            checkStatusAllOptions.filter(item => item.value === record.check_status).length > 0
              ? checkStatusAllOptions.filter(item => item.value === record.check_status)[0].label
              : '--'
          }}
        </div>
      </template>
      <template slot="card_img" slot-scope="record">
        <my-image
          :imgWidth="40"
          style="margin: 0px auto"
          v-if="getImgUrl(record, 1)"
          :imgUrl="getImgUrl(record, 1)"
        />
      </template>
      <template slot="price_img" slot-scope="record">
        <my-image
          :imgWidth="40"
          style="margin: 0px auto"
          v-if="getImgUrl(record, 2)"
          :imgUrl="getImgUrl(record, 2)"
        />
      </template>
      <template slot="other_img" slot-scope="record">
        <my-image
          :imgWidth="40"
          style="margin: 0px auto"
          v-if="getImgUrl(record, 3)"
          :imgUrl="getImgUrl(record, 3)"
        />
      </template>
      <template slot="share_link" slot-scope="record">
        <div class="share-link" v-if="record.share_link">
          <span>已上传</span>
          <a-icon class="copy-icon" type="copy" @click="copyClick(record.share_link)" />
        </div>
        <span v-else> 待上传 </span>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button
          v-if="
            record.check_status === null || record.check_status === 0 || record.check_status === 4
          "
          type="primary"
          size="small"
          @click="handleApprovalGoods(record)"
          >审核</a-button
        >
        <a-button
          v-else
          type="primary"
          size="small"
          class="look"
          @click="handleApprovalGoods(record, 1)"
          >查看</a-button
        >
      </template>
    </a-table>
    <div v-if="createGoodsDrawerVisiable">
      <a-drawer
        placement="right"
        width="800"
        @close="closeClick"
        :visible="createGoodsDrawerVisiable"
      >
        <template slot="title">
          <div class="drawer-title-div">
            商品复选<span class="drawer-title">{{
              checkStatusAllOptions.filter(item => item.value === goodsRow.check_status).length > 0
                ? checkStatusAllOptions.filter(item => item.value === goodsRow.check_status)[0]
                    .label
                : ''
            }}</span>
          </div>
        </template>
        <audit-detail
          :readOnly="readOnly"
          v-on:closeClick="closeClick"
          v-on:reload="reload"
          :detailData="goodsRow"
          :step="2"
        />
      </a-drawer>
    </div>
    <div v-if="showAllVisiable">
      <a-drawer
        title="高级筛选"
        class="select-div"
        placement="right"
        width="346"
        @close="closeClick"
        :visible="showAllVisiable"
      >
        <select-query-form
          v-on:closeClick="closeClick"
          v-on:reset="reset"
          v-on:reload="reload"
          :query-form="queryParams"
          :step="2"
          :list-loading="listLoading"
        />
      </a-drawer>
    </div>
    <upload
      :visible="isModalVisible"
      :detailData="goodsRow"
      @close="isModalVisible = false"
    ></upload>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';

import auditDetail from '@/pages/admin/merchant/dialog/auditDetail';
import upload from '@/pages/admin/merchant/dialog/upload';
import { exportMerchantChooseGoodsList, getChooseGoodsList } from '@/service/merchant';
import { checkStatusAllOptions } from '@/pages/admin/merchant/use';
import selectQueryForm from '@/pages/admin/merchant/dialog/selectQueryForm';

export default defineComponent({
  name: 'KrProductChoose',
  components: {
    auditDetail,
    upload,
    selectQueryForm,
  },
  methods: {
    copyClick(share_link) {
      var element = this.createElement(share_link);
      element.select();
      element.setSelectionRange(0, element.value.length);
      document.execCommand('copy');
      element.remove();
      this.$message.success('复制精选链接成功！');
    },
    //创建临时的输入框元素
    createElement(text) {
      var isRTL = document.documentElement.getAttribute('dir') === 'rtl';
      var element = document.createElement('textarea');
      // 防止在ios中产生缩放效果
      element.style.fontSize = '12pt';
      // 重置盒模型
      element.style.border = '0';
      element.style.padding = '0';
      element.style.margin = '0';
      // 将元素移到屏幕外
      element.style.position = 'absolute';
      element.style[isRTL ? 'right' : 'left'] = '-9999px';
      // 移动元素到页面底部
      let yPosition = window.pageYOffset || document.documentElement.scrollTop;
      element.style.top = `${yPosition}px`;
      //设置元素只读
      element.setAttribute('readonly', '');
      element.value = text;
      document.body.appendChild(element);
      return element;
    },
    paginationHandler(params) {
      if (params.pageSize !== this.queryParams.page_size) {
        this.queryParams.page = 1;
        this.pagination.current = 1;
        this.queryParams.page_size = params.pageSize;
      } else {
        this.pagination.current = params.current;
        this.queryParams.page = params.current;
      }
      this.reload(false);
    },
  },
  setup(_, ctx) {
    const createGoodsDrawerVisiable = ref(false);
    const goodsRow = ref({});
    const readOnly = ref(false);
    const handleApprovalGoods = (row, type = 0) => {
      createGoodsDrawerVisiable.value = true;
      goodsRow.value = row;
      readOnly.value = type === 1;
    };
    const showAllVisiable = ref(false);
    const showAllQuery = () => {
      showAllVisiable.value = true;
    };
    const windowHeight = document.documentElement.clientHeight || 1000;
    const tableScrollY = ref(windowHeight - 280);
    const onTopCardRectUpdate = rect => {
      tableScrollY.value = windowHeight - 190 - rect.height;
    };
    // 商品列表
    const goodsColumns = [
      {
        title: '序号',
        width: 100,
        align: 'left',
        dataIndex: 'id',
      },
      {
        title: '图片',
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'goods_img' },
      },
      {
        title: '商品ID',
        dataIndex: 'item_id',
        align: 'left',
        ellipsis: true,
        width: 190,
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '店铺名称',
        dataIndex: 'shop_name',
        width: 200,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return text || '--';
        },
      },
      /* {
         title: '店铺类型',
         dataIndex: 'brand_type',
         width: 100,
         ellipsis: true,
         align: 'center',
         customRender: text => {
           return text === 1 ? '品牌方' : text === 2 ? '代理商' : text === 3 ? '黑标' : '--';
         },
       },
       {
         title: '一级分类',
         dataIndex: 'merchant_category',
         width: 120,
         ellipsis: true,
         align: 'center',
         customRender: text => {
           return text || '--';
         },
       },*/
      {
        title: '商品名称',
        dataIndex: 'item_name',
        width: 200,
        ellipsis: true,
        align: 'left',
      },

      /* {
        title: '手卡',
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'card_img' },
      },
      {
        title: '比价图',
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'price_img' },
      },
      {
        title: '其它资料',
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'other_img' },
      },*/
      {
        title: '销售价',
        dataIndex: 'kuran_price',
        align: 'right',
        ellipsis: true,
        width: 100,
        customRender: (text, record) => {
          let price = record?.kuran_price;
          if (price !== null && price !== undefined && price !== '') {
            price = '￥' + price;
          } else {
            price = '--';
          }
          return price;
        },
      },
      /* {
        title: '公开佣金',
        scopedSlots: { customRender: 'item_id__commission_rate' },
        width: 100,
        align: 'right',
      },*/
      {
        title: '达人佣金',
        scopedSlots: { customRender: 'item_id__exclusive_commission_rate' },
        width: 100,
        align: 'right',
      },
      {
        title: '团长佣金',
        scopedSlots: { customRender: 'item_id__colonel_commission_rate' },
        width: 100,
        align: 'right',
      },
      {
        title: '价格/机制',
        dataIndex: 'description',
        align: 'left',
        width: 100,
        ellipsis: true,
        customRender: text => {
          return text || '--';
        },
      },
      /*  {
        title: '赠品工具',
        dataIndex: 'giveaway_tool',
        align: 'center',
        width: 100,
        customRender: text => {
          return text === 1 ? '已开启' : '未开启';
        },
      },

      {
        title: '赠品价格/机制',
        dataIndex: 'giveaway_description',
        align: 'left',
        width: 130,
        ellipsis: true,
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '多拍链接',
        dataIndex: 'is_minimum_per_order',
        align: 'center',
        width: 100,
        ellipsis: true,
        customRender: text => {
          return text === 1 ? '已设置' : '未设置';
        },
      },
      {
        title: '库存',
        dataIndex: 'stock',
        width: 100,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },

      {
        title: '店铺口碑分',
        dataIndex: 'item_id__merchant_experience_score',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '店铺体验分',
        dataIndex: 'item_id__service_score',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '发货周期',
        dataIndex: 'delivery_cycle',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      {
        title: '运费险',
        dataIndex: 'freight_policy',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text === 1 ? '有' : '无';
        },
      },*/
      {
        title: '对接招商',
        dataIndex: 'contact__username',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      /* {
        title: '公司',
        dataIndex: 'supplier',
        width: 200,
        ellipsis: true,
        align: 'left',
        customRender: text => {
          return text || '--';
        },
      },*/
      {
        title: '状态',
        scopedSlots: { customRender: 'check_status' },
        align: 'center',
        width: 110,
      },
      {
        title: '到样日期',
        dataIndex: 'sample_date',
        width: 120,
        ellipsis: true,
        align: 'center',
        customRender: text => {
          return text || '--';
        },
      },
      /*  {
        title: '精选链接',
        scopedSlots: { customRender: 'share_link' },
        width: 120,
        ellipsis: true,
        align: 'center',
      },

      {
        title: '是否退样',
        scopedSlots: { customRender: 'is_recycle' },
        width: 100,
        align: 'center',
      },*/
      {
        title: '操作',
        align: 'center',
        fixed: 'right',
        width: 100,
        scopedSlots: { customRender: 'operation' },
      },
    ];

    const list = ref([]);
    const pagination = ref({
      size: 'small',
      current: 1,
      pageSize: 20,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      showTotal: total =>
        `共 ${total} 条记录 第 ${pagination.value.current}/${Math.ceil(
          total / pagination.value.pageSize,
        )} 页`,
      showQuickJumper: true,
      showSizeChanger: true,
      onShowSizeChange: (current, pageSize) => (pagination.value.pageSize = pageSize),
    });
    const queryParams = ref({
      page: 1,
      page_size: 20,
      item_name: '',
      shop_name: '',
      supplier_name: '',
      check_status: undefined,
      id: '',
      item_id: '',
      sample_express_number: '',
      exclusive_commission_rate: ['', ''],
      item_id__colonel_commission_rate: ['', ''],
      item_id__commission_rate: ['', ''],
      merchant_experience_score: ['', ''],
      service_score: ['', ''],
      contact_username: '',
      merchant_category: '',
      brand_type: '',
      is_recycle: undefined,
      is_clear: undefined,
      sample_date: undefined,
    });
    const reset = () => {
      queryParams.value = {
        page: 1,
        page_size: queryParams.value.page_size,
        item_name: '',
        shop_name: '',
        supplier_name: '',
        check_status: undefined,
        id: '',
        item_id: '',
        sample_express_number: '',
        exclusive_commission_rate: ['', ''],
        item_id__colonel_commission_rate: ['', ''],
        item_id__commission_rate: ['', ''],
        merchant_experience_score: ['', ''],
        service_score: ['', ''],
        contact_username: '',
        merchant_category: '',
        brand_type: '',
        is_recycle: undefined,
        is_clear: undefined,
        sample_date: undefined,
      };
      reload();
    };
    const listLoading = ref(false);
    const getGoodsList = async () => {
      listLoading.value = true;
      const exclusive_commission_rate_le =
        queryParams.value.exclusive_commission_rate[1] ||
        queryParams.value.exclusive_commission_rate[1] === 0
          ? Number(queryParams.value.exclusive_commission_rate[1] || 0) * 100
          : '';
      const exclusive_commission_rate_ge =
        queryParams.value.exclusive_commission_rate[0] ||
        queryParams.value.exclusive_commission_rate[0] === 0
          ? Number(queryParams.value.exclusive_commission_rate[0] || 0) * 100
          : '';
      const item_id__colonel_commission_rate_le =
        queryParams.value.item_id__colonel_commission_rate[1] ||
        queryParams.value.item_id__colonel_commission_rate[1] === 0
          ? Number(queryParams.value.item_id__colonel_commission_rate[1] || 0) * 100
          : '';
      const item_id__colonel_commission_rate_ge =
        queryParams.value.item_id__colonel_commission_rate[0] ||
        queryParams.value.item_id__colonel_commission_rate[0] === 0
          ? Number(queryParams.value.item_id__colonel_commission_rate[0] || 0) * 100
          : '';
      const item_id__commission_rate_le =
        queryParams.value.item_id__commission_rate[1] ||
        queryParams.value.item_id__commission_rate[1] === 0
          ? Number(queryParams.value.item_id__commission_rate[1] || 0) * 100
          : '';
      const item_id__commission_rate_ge =
        queryParams.value.item_id__commission_rate[0] ||
        queryParams.value.item_id__commission_rate[0] === 0
          ? Number(queryParams.value.item_id__commission_rate[0] || 0) * 100
          : '';
      const { err, res } = await getChooseGoodsList({
        sample_date: queryParams.value.sample_date,
        page: queryParams.value.page,
        page_size: queryParams.value.page_size,
        item_name: queryParams.value.item_name,
        id: queryParams.value.id,
        item_id: queryParams.value.item_id,
        shop_name: queryParams.value.shop_name,
        supplier_name: queryParams.value.supplier_name,
        check_status: queryParams.value.check_status,
        sample_express_number: queryParams.value.sample_express_number,
        exclusive_commission_rate_le: exclusive_commission_rate_le,
        exclusive_commission_rate_ge: exclusive_commission_rate_ge,
        item_id__colonel_commission_rate_le: item_id__colonel_commission_rate_le,
        item_id__colonel_commission_rate_ge: item_id__colonel_commission_rate_ge,
        item_id__commission_rate_le: item_id__commission_rate_le,
        item_id__commission_rate_ge: item_id__commission_rate_ge,
        merchant_experience_score_ge: queryParams.value.merchant_experience_score[0],
        merchant_experience_score_le: queryParams.value.merchant_experience_score[1],
        service_score_ge: queryParams.value.service_score[0],
        service_score_le: queryParams.value.service_score[1],
        contact_username: queryParams.value.contact_username,
        merchant_category: queryParams.value.merchant_category,
        brand_type: queryParams.value.brand_type,
        is_recycle: queryParams.value.is_recycle,
        is_clear: queryParams.value.is_clear,
      });
      listLoading.value = false;
      if (!err) {
        if (res.success) {
          queryParams.value.id = '';
          const one_pagination = { ...pagination.value };
          list.value = res.data.results;
          one_pagination.total = res.data.count;
          pagination.value = one_pagination;
        }
      }
    };

    const reload = async (clean = true, formData = null) => {
      if (clean) {
        pagination.value.current = 1;
        queryParams.value.page = 1;
      }
      if (formData) {
        queryParams.value = formData;
      }
      await getGoodsList();
    };

    const closeClick = () => {
      createGoodsDrawerVisiable.value = false;
      showAllVisiable.value = false;
    };
    const isModalVisible = ref(false);
    const handleUploadGoods = row => {
      isModalVisible.value = true;
      goodsRow.value = row;
    };
    // 导出列表处理
    const exportLoading = ref(false);
    const exportListClick = async () => {
      exportLoading.value = true;
      const exclusive_commission_rate_le =
        queryParams.value.exclusive_commission_rate[1] ||
        queryParams.value.exclusive_commission_rate[1] === 0
          ? Number(queryParams.value.exclusive_commission_rate[1] || 0) * 100
          : '';
      const exclusive_commission_rate_ge =
        queryParams.value.exclusive_commission_rate[0] ||
        queryParams.value.exclusive_commission_rate[0] === 0
          ? Number(queryParams.value.exclusive_commission_rate[0] || 0) * 100
          : '';
      const item_id__colonel_commission_rate_le =
        queryParams.value.item_id__colonel_commission_rate[1] ||
        queryParams.value.item_id__colonel_commission_rate[1] === 0
          ? Number(queryParams.value.item_id__colonel_commission_rate[1] || 0) * 100
          : '';
      const item_id__colonel_commission_rate_ge =
        queryParams.value.item_id__colonel_commission_rate[0] ||
        queryParams.value.item_id__colonel_commission_rate[0] === 0
          ? Number(queryParams.value.item_id__colonel_commission_rate[0] || 0) * 100
          : '';
      const item_id__commission_rate_le =
        queryParams.value.item_id__commission_rate[1] ||
        queryParams.value.item_id__commission_rate[1] === 0
          ? Number(queryParams.value.item_id__commission_rate[1] || 0) * 100
          : '';
      const item_id__commission_rate_ge =
        queryParams.value.item_id__commission_rate[0] ||
        queryParams.value.item_id__commission_rate[0] === 0
          ? Number(queryParams.value.item_id__commission_rate[0] || 0) * 100
          : '';
      const { res } = await exportMerchantChooseGoodsList({
        sample_date: queryParams.value.sample_date,
        item_name: queryParams.value.item_name,
        id: queryParams.value.id,
        item_id: queryParams.value.item_id,
        shop_name: queryParams.value.shop_name,
        supplier_name: queryParams.value.supplier_name,
        check_status: queryParams.value.check_status,
        sample_express_number: queryParams.value.sample_express_number,
        exclusive_commission_rate_le: exclusive_commission_rate_le,
        exclusive_commission_rate_ge: exclusive_commission_rate_ge,
        item_id__colonel_commission_rate_le: item_id__colonel_commission_rate_le,
        item_id__colonel_commission_rate_ge: item_id__colonel_commission_rate_ge,
        item_id__commission_rate_le: item_id__commission_rate_le,
        item_id__commission_rate_ge: item_id__commission_rate_ge,
        merchant_experience_score_ge: queryParams.value.merchant_experience_score[0],
        merchant_experience_score_le: queryParams.value.merchant_experience_score[1],
        service_score_ge: queryParams.value.service_score[0],
        service_score_le: queryParams.value.service_score[1],
        contact_username: queryParams.value.contact_username,
        merchant_category: queryParams.value.merchant_category,
        brand_type: queryParams.value.brand_type,
        is_recycle: queryParams.value.is_recycle,
        is_clear: queryParams.value.is_clear,
      });
      exportLoading.value = false;
      if (res && res.success) {
        location.href = res.data.detail_url;
        ctx.root.$message.success('导出成功');
      } else {
        ctx.root.$message.error('导出失败');
      }
    };
    const getImgUrl = (row, type = 1) => {
      if (row.attachment && row.attachment.length > 0) {
        let url = '';
        row.attachment.map(item => {
          if (item.type === type) {
            url = item.url || '';
          }
        });
        return url;
      } else {
        return '';
      }
    };
    return {
      reset,
      showAllQuery,
      showAllVisiable,
      getImgUrl,
      exportLoading,
      readOnly,
      exportListClick,
      handleUploadGoods,
      isModalVisible,
      closeClick,
      onTopCardRectUpdate,
      goodsRow,
      handleApprovalGoods,
      createGoodsDrawerVisiable,
      pagination,
      listLoading,
      goodsColumns,
      checkStatusAllOptions,
      tableScrollY,
      list,
      queryParams,
      reload,
    };
  },
  created() {
    this.reload();
  },
});
</script>

<style lang="less">
.drawer-title-div {
  display: flex;
  align-items: center;
  .drawer-title {
    display: inline-block;
    color: #e83741;
    border: 1px solid #e83741;
    //width: 60px;
    padding: 0 12px;
    height: 24px;
    line-height: 22px;
    border-radius: 12px;
    margin-left: 20px;
    text-align: center;
    font-size: 12px;
  }
}
.shop-product-list-page {
  display: flex;
  flex-direction: column;
  .share-link {
    display: flex;
    justify-content: center;
    .copy-icon {
      margin-left: 4px;
      padding-top: 3px;
      font-size: 15px;
      color: #999999;
      &:hover {
        color: #00aaff;
      }
    }
  }

  .mr-10 {
    margin-right: 10px;
  }
  .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }
  .ant-table-tbody > tr,
  .ant-table-tbody > tr > td {
    height: 56px !important;
  }
  /deep/ .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }
  /deep/.ant-table-tbody > tr {
    height: 56px !important;
  }
  /deep/.ant-table-tbody > tr > td {
    height: 56px !important;
  }
}
</style>
